import React, { useRef } from "react";
import './Button.scss'
import { useIntl } from "react-intl";

const Button = (props) => {
    let intl = useIntl(null)

    let classeNames = "Button"
    if(props.type === "primary") classeNames+=' primary'
    else if(props.type === "secondary") classeNames+=' secondary'
    else classeNames+=' tertiary'
    if(props.icon && !props.label) classeNames+=' iconButton'
    else if(props.icon && props.label) classeNames+=' iconLabelButton'
    if(props.size=== "small") classeNames+=' small'
    else if(props.size=== "large") classeNames+=' large'
    else classeNames+=' medium'
    if(props.circle) classeNames+=' circleButton'

    const linkRef = useRef()

    const handleClick = (event) => {
        props.onClick(event)
    }

    return(
        !props.submit?
            <div ref={props.ref} className={classeNames} onClick={(e) => {handleClick(e)}}>
                {props.icon && <img className="ButtonIcon" src={props.icon}/>}
                {props.label && <div className="ButtonLabel">{intl.formatMessage({id:props.label})}</div>}
            </div>
            :
            <div ref={props.ref} className="ButtonContainer">
                <label htmlFor={'submitBtn'} className={classeNames}>
                    {props.icon && <img className="ButtonIcon" src={props.icon}/>}
                    {props.label && <div className="ButtonLabel">{intl.formatMessage({id:props.label})}</div>}
                </label>
                <input type="submit" id={'submitBtn'}/>
            </div>
    )
}

export default Button