export const fr={
    //Commons
    'name' : 'Ian Bertin',
    'old':'19 ans',
    'hobbies':'Musculation, Jeux vidéos, Jeux de rôle',

    'nameLabel':'Nom : ',
    'oldLabel':'Age : ',
    'hobbiesLabel':'Loisirs : ',

    'html':'HTML 5',
    'css':'CSS 3',
    'js':'Javascript',
    'react':'React Js',
    'npm':'Node Js',
    'php':'PHP',
    'angular':'Angular',
    'ionic':'Ionic',
    'cpp':'C++',
    'java':'Java',
    'csharp':'C#',
    'py':'Python',
    'mysql':"MySql",
    'sqlite':'SQLite',
    'sql':'PL/SQL',
    'mongo':'MongoDB',
    'webpack':'Webpack',
    'git':'GitLab',
    'jest':'Jest',
    'unity':'Unity',

    'teamwork':"Travail d'équipe",
    'agile':"Méthodes agiles",
    'owner':'Product owner',


    'description':'Description',
    'visualizations':'Visualisations',
    'skills-used':'Compétences mobilisées',

    'submit':'Envoyer',

    //Menu
    'CaseMenu1': 'À propos',
    'CaseMenu2': 'Expériences',
    'CaseMenu3': 'Compétences',
    'CaseMenu4': 'Projets',
    'CaseMenu5': 'Me contacter',

    //Home Page
    'homePage-intro': 'Bonjour, je suis',
    'homePage-status-1': 'Étudiant en informatique.',
    'homePage-status-2': 'Développeur fullstack.',
    'homePage-status-3': 'Développeur Web.',
    'homePage-presentation': 'Développeur web et fullstack en troisième année de BUT informatique.',

    //About Page
    'aboutPage-title':'À propos',
    'aboutPage-subtitle':'En savoir plus sur moi',

    'aboutPage-about-title':'Présentation',
    'aboutPage-about-description':"Je suis Ian Bertin, j'ai 19 ans et je suis passionné par l'informatique depuis petit, et plus précisément par la programmation d'applications. J'ai toujours voulu en faire mon métier et c'est la raison pour laquelle je me suis orienté vers cette spécialisation dès le lycée. Dans la vie, mes principaux centres d'intérêts sont le sport, comme la musculation que je pratique plusieurs fois par semaine, la guitare ainsi que les jeux vidéo et tout ce qui touche à la Pop culture.",

    'aboutPage-education-title':'Formation',
    'aboutPage-education-description':"Je suis actuellement en 3ème année de BUT informatique à l'IUT informatique d'Aix-Marseille site d'Arles, où je réalise le parcours A - Réalisation d'applications : conception, développement, validation.",
    
    'aboutPage-certification-title':'Diplomes',
    'aboutPage-certification-description':"J'ai obtenu en 2021 mon baccalaureat général spécialités mathématiques et NSI (numérique, sciences de l'informatique) avec mention.",

    'aboutPage-button':'Télécharger mon CV',

    //Experience Page
    'experiencePage-title':'Expériences',
    'experiencePage-subtitle':'Mes expériences professionnelles',

    'experiencePage-exp1-name':'Sogeti High-Tech',
    'experiencePage-exp1-type':"Stage d'observation",
    'experiencePage-exp1-date':'décembre 2017',
    'experiencePage-exp1-description':"Dans le cadre de mon stage d'observation de troisième, j'ai effectué une semaine en entreprise chez Sogeti High-tech. Cela m'a permis de découvrir divers métiers tels que celui d'ingénieur informaticien ou d'ingénieur mécanicien. Cette expérience représente ma première incursion dans le monde du travail.",

    'experiencePage-exp2-name':'Everdyn',
    'experiencePage-exp2-type':"Stage",
    'experiencePage-exp2-date':'Mars 2023 - Juin 2023',
    'experiencePage-exp2-description':"Dans le cadre de ma deuxième année de BUT informatique, j'ai effectué un stage de 3 mois chez Everdyn à Aix-en-Provence, au cours duquel j'ai pu explorer les technologies de C# et .Net Maui.",

    'experiencePage-exp3-name':'Arkilium dynalic screen (ADS)',
    'experiencePage-exp3-type':'Alternance',
    'experiencePage-exp3-date':'Aout 2023 - Juin 2024',
    'experiencePage-exp3-description':"Je suis actuellement en troisième année de BUT informatique en alternance chez ADS (Arkilium Dynamic Screen) à Avignon. Au sein de cette entreprise, je perfectionne mes compétences en React.js et en développement web.",

    //Skills Page
    'skillsPage-title':'Compétences',
    'skillsPage-subtitle':'Un aperçu de mes compétences',
    'skillsPage-devweb':'Dev Web',
    'skillsPage-devoo':'Dev orienté objet',
    'skillsPage-devmobile':'Dev Mobile',
    'skillsPage-bdd':'Bases de données',
    

    //Projects Page
    'projectsPage-title':'Projets',
    'projectsPage-subtitle':"Certains projets que j'ai réalisés",
    'projectsPage-view-project-button':'Voir le projet',
    'projectsPage-dl-project-button':'Télécharger le projet',

    
    //Projects
    'project1-title':'Pokemon Team Builder',
    'project1-description':"Un team builder pokemon réalisé en Javascript à l'aide des technologies de Webpack et Node Js.",
    'project1-longDescription':"Afin de continuer mon apprentissage du développement web, et plus particulièrement du développement en JavaScript avec l'aide de Webpack et de Node.js, j'ai dû réaliser un projet d'application client-riche à la fin du troisième semestre de mon BUT Informatique. Le sujet du projet était libre, tant que nous respectons les fonctionnalités suivantes : le projet devait être codé en programmation orientée objet, l'application doit être exportée via un module empaqueté par Webpack, toutes les dépendances doivent être gérées par Webpack, et le contenu de l'application doit reposer sur au moins deux requêtes XHR ou Fetch dépendant l'une de l'autre. J'ai donc choisi d'utiliser l'API 'pokeApi' pour créer un Pokédex stratégique permettant de consulter les informations d'un Pokémon sélectionné et de créer une équipe de six Pokémon afin de l'analyser et découvrir ses forces et faiblesses.",
    'project1-date':'Décembre 2022',

    'project2-title':"Agenda de la ville d'Arles",
    'project2-description':"Un agenda réalisé en React Js permettant de consulter les évènements de la ville d'Arles.",
    'project2-longDescription':"Pendant mon apprentissage de React Js, j'ai dû concevoir un agenda répertoriant les évènements par date dans la ville d'Arles. Cet agenda devait être capable de récupérer une liste d'évènements au format JSON et de les afficher selon deux vues différentes : une vue mensuelle, présentant le nombre d'évènements par jour sous la forme d'un calendrier que l'on peut parcourir mois par mois et année par année. On peut afficher la liste des évènements d'une journée en cliquant dessus et voir les détails d'un événement en cliquant sur son nom dans la liste. Ensuite, une vue planning présente de manière synthétique tous les évènements se déroulant d'une date saisie jusqu'à la fin du mois, classés par ordre d'arrivée et par jour. J'ai réalisé ce projet seul à la fin du quatrième semestre de mon BUT informatique.",
    'project2-date':'Mai 2023',

    'project3-title':"CG:SHOP 2023 Visualisation",
    'project3-description':"Un site de visualisation des solutions de l'équipe Shadoks du concours CG:SHOP 2023.",
    'project3-longDescription':"Dans le cadre de la situation d'apprentissage évaluée de ma deuxième année de BUT informatique, j'ai pris part, avec mon groupe de projet, à la conception d'un site web permettant la visualisation des solutions de l'équipe Shadoks pour le concours CG:SHOP 2023. Ce concours de géométrie requiert des participants le remplissage de polygones d'instances avec le minimum possible de polygones convexes. Notre approche pour ce projet s'est basée sur la méthode agile, nous permettant ainsi d'apprendre cette méthodologie de travail et de réaliser pour la première fois un projet similaire à ceux réalisés en entreprise. J'ai assumé le rôle de product owner au sein de ce groupe de projet.",
    'project3-link':"https://pageperso.lis-lab.fr/guilherme.fonseca/cgshop23view/",
    'project3-date':'Février 2023',

    'project4-title':'Présentation du BUT Informatique',
    'project4-description':'Un site web réalisé en HTML/CSS de présentation du BUT Informatique.',
    'project4-longDescription':"Dans le cadre d'une Situation d'Apprentissage Évaluée (SAÉ) du premier semestre du BUT Informatique que j'ai réalisée, l'objectif était de créer un site vitrine en HTML/CSS présentant mon diplôme et toutes ses spécificités. Ce site devait inclure une présentation détaillée des parcours proposés en BUT informatique, des compétences enseignées, des débouchés possibles, etc. Nous avons travaillé sur ce projet en groupes de 4 personnes.",
    'project4-date':'Décembre 2021',

    'project5-title':'Puissance 4',
    'project5-description':'Un jeu de puissance 4 réalisé en React Js.',
    'project5-longDescription':"Au début de ma troisième année de BUT informatique, j'ai eu l'occasion de concevoir un jeu de Puissance 4 fonctionnel en React JS. Ce projet présentait la particularité d'exiger la mise en place de jeux de tests unitaires, dans mon cas réalisés avec Jest, qui devaient être automatisés grâce aux pipelines de GitLab.",
    'project5-date':'Décembre 2023',
    
    'project6-title':'EscApe',
    'project6-description':"Un jeu de platforme réalisé à l'occasion de la DevArt 2024.",
    'project6-longDescription':"Lors de la fin du 5ème semestre de mon cursus en BUT informatique, mon IUT a organisé une DevArt : un événement qui consiste en la réalisation d’un projet autour d’un thème spécifique, et ce, en seulement 32 heures d’affilée. En effet, nous étions par groupes de trois et devions rester à l'IUT durant ces 32 heures, pendant lesquelles nous étions libres de laisser place à notre imagination afin de produire une création mêlant développement informatique et art. Le thème de cette année était \"les portes\", un sujet très ouvert nous permettant de réaliser facilement quelque chose d'unique. Nous sommes rapidement partis sur un projet de création de jeu vidéo, n’ayant jamais eu l'occasion d’en réaliser, grâce à la technologie de Unity 2D, une technologie très facile à prendre en main et très bien documentée sur Internet. Afin que notre jeu soit conforme au thème, nous avons décidé de réaliser un \"platformer\" dans lequel chacun des niveaux est relié par une porte. Cependant, il nous est vite venu à l'esprit que les portes n'étaient pas forcément physiques ; elles pouvaient aussi être très métaphoriques, et ce, quant à toutes les significations qu'on peut leur trouver. Bien qu’il en ait l'air, notre jeu n’est donc pas qu’un simple jeu de plateforme enfantin dans lequel nous incarnons un petit singe voyageant d’un monde à l’autre ; il raconte une histoire beaucoup plus sombre. En effet, à travers ce singe, nous racontons à nos joueurs l’histoire d’un jeune garçon s’étant suicidé à cause du harcèlement scolaire, et qui revit son traumatisme en s’enfonçant peu à peu dans ses souvenirs, porte après porte. Nous avons profité de cet événement et de ce thème afin de parler d’un sujet qui nous tenait à cœur, et nous sommes très fiers du résultat, nous qui n'avions encore jamais conçu de jeu vidéo à ce jour.",
    'project6-file-name':"EscApe.zip",
    'project6-date':'Février 2024',

    //Contact Page
    'contactPage-title':'Me contacter',
    'contactPage-subtitle':'Mes coordonnées',

    'contactPage-contact-call-me':"Appelez-moi",
    'contactPage-contact-phone-number':"+33 7 82 96 77 60",

    'contactPage-contact-email':"Email",
    'contactPage-contact-email-address':"ian.bertin2004@gmail.com",

    'contactPage-contact-address':"Adresse",
    'contactPage-contact-location':"13330, Pélissanne",

    'contactPage-contact-sn':"Résaux sociaux",
    'linkedin-link':"https://www.linkedin.com/in/ian-bertin-1b888a205/",

    'contactPage-form-title':'Contactez-moi :',
    'contactPage-input-name':'Votre nom...',
    'contactPage-input-email':'Votre adresse mail...',
    'contactPage-input-object':'Objet...',
    'contactPage-input-message':'Écrivez votre message ici...',

    //Footer
    'footer-copyright':'© 2024 Ian Bertin',
}