import React, { PureComponent } from "react";
import "./CarouselContent.scss"
import { useIntl } from "react-intl";
import Heading from "./Heading";
import Separator from "./Separator";
import Body from "./Body";
import PopupContent from "./PopupContent";

const CarouselContent = (props) =>{
    const intl = useIntl(null)

    return (
        <div className="CarouselContent" onClick={(e) => {props.onClick(e, <PopupContent datas={props.data}/>)}}>
            <div className="CarouselContentBackground"><img src={props.data.image}/></div>
            <div className="CarouselContentBackgroundColor">
                <div className="CarouselContentLabel"><Heading color={2} size={'h5'}>{intl.formatMessage({id:props.data.label})}</Heading></div>
                <div className="CarouselContentDate"><Body center small color={2}>{intl.formatMessage({id:props.data.date})}</Body></div>
                <div className="CarouselContentDescription"><Body center small color={2}>{intl.formatMessage({id:props.data.description})}</Body></div>
            </div>
        </div>
    )
}

export default CarouselContent