import React, { PureComponent, useMemo } from "react";
import "./Home.scss"
import Heading from './components/Heading'
import { useIntl } from "react-intl";
import Pages from "./components/Pages";
import ArrowDown from '../assets/icons/arrow-down-white.png'
import Button from "./components/Button";
import ReactTypingEffect from 'react-typing-effect';
import Body from "./components/Body";

const Home = (props) =>{
    const intl = useIntl(null)

    const handleButtonClick = (e) =>{
        window.scrollTo({
            top: window.innerHeight-60,
            behavior: 'smooth'
        });
    }

    return (
        <div className="Home">
            <Pages>
                <div className="HomeBackground"/>
                <div className="HomeBackgroundOpacity"/>
                <div className="HomeContent">
                    <div className="HomeIntroContainer">
                        <div className="HomeIntro"><Body color={3}>{intl.formatMessage({id : 'homePage-intro'})}</Body></div>
                        <Heading color={2} uppercase>{intl.formatMessage({id : 'name'})}</Heading>
                        <div className="HomeStatus">
                            <ReactTypingEffect 
                                text={
                                    [intl.formatMessage({id : 'homePage-status-1'}), 
                                    intl.formatMessage({id : 'homePage-status-2'}),
                                    intl.formatMessage({id : 'homePage-status-3'})]
                                }
                                cursorRenderer={cursor => <Heading size="h2" color={2}>{cursor}</Heading>}
                                displayTextRenderer={(text, i) => (<Heading size="h2" key={i} color={4}>{text}</Heading>)}        
                            />
                        </div>
                        <div className="HomePresentation">
                            <Body color={3}>{intl.formatMessage({id : 'homePage-presentation'})}</Body>
                        </div>
                    </div>
                    <div className="AboutBtn">
                        <Button size={'small'} background={false} type="tertiary" icon={ArrowDown} onClick={(e) => {handleButtonClick(e)}}/>
                    </div>
                </div> 
            </Pages>
        </div>
    )
}

export default Home