import React, { forwardRef } from 'react';
import { useIntl } from "react-intl";
import './Experience.scss'
import Pages from "./components/Pages";
import Timeline from "./components/Timeline";

const Experience = forwardRef((props, ref) => {
    const intl = useIntl()

    const experiences = [
        {title:'experiencePage-exp1-name', subtitle:'experiencePage-exp1-type', date:'experiencePage-exp1-date', description:'experiencePage-exp1-description'},
        {title:'experiencePage-exp2-name', subtitle:'experiencePage-exp2-type', date:'experiencePage-exp2-date', description:'experiencePage-exp2-description'},
        {title:'experiencePage-exp3-name', subtitle:'experiencePage-exp3-type', date:'experiencePage-exp3-date', description:'experiencePage-exp3-description'},
    ]

    return(
        <div className="Experience" ref={ref}>
            <Pages title={'experiencePage-title'} subTitle={'experiencePage-subtitle'}>
                <div className="ExperienceContent">
                    <Timeline datas={experiences}/>
                </div>
            </Pages>
        </div>
    )
})

export default Experience