import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import './TimelineCase.scss'
import Heading from "./Heading";
import Body from "./Body";
import ArrowDownIcon from './../../assets/icons/ico_arrow_down_fill.svg'
import TimerIcon from './../../assets/icons/ico_timer.svg'
import Separator from "./Separator";

const TimelineCase = (props) => {
    const intl = useIntl()

    return(
        <div className={"TimelineCase" + (props.left?" left":" right")}>
            <div className="TimelineCaseTitle">
                <div className="title"><Heading size={'h4'}>{intl.formatMessage({id : props.data.title})}</Heading></div>
                <div className="subtitle"><Heading size={'h5'} color={3}>{intl.formatMessage({id : props.data.subtitle})}</Heading></div>
                <div className="time">
                    <div className="timeImg"><img src={TimerIcon}/></div>
                    <Body small color={3}>{intl.formatMessage({id : props.data.date})}</Body>
                </div>
                <Separator/>
            </div>
            <div className="TimelineCaseDescription"><Body>{intl.formatMessage({id : props.data.description})}</Body></div>
            <div className={"TimelineCaseArrow" + (props.left?" left":" right")}><img src={ArrowDownIcon}/></div>
        </div>
    )
}

export default TimelineCase