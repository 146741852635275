import React, { useEffect, useState } from "react";
import './Input.scss'
import { useIntl } from "react-intl";

const Input = (props) => {
    let intl = useIntl(null)

    const [value, setValue] = useState(props.value)

    useEffect(()=>{
        setValue(props.value)
    }, [props.value])

    let classeNames = "Input"

    return(
        <div className={classeNames}>
            {props.type === 'textarea'?
                <textarea value={value} name={props.name} onChange={(e) => {props.onChange(e)}} placeholder={intl.formatMessage({id:props.placeholder})} required={props.required}/>
            :
                <input value={value} name={props.name} onChange={(e) => {props.onChange(e)}} type={props.type} placeholder={intl.formatMessage({id:props.placeholder})} required={props.required}/>
            }
        </div>
    )
}

export default Input