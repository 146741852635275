import React, { PureComponent, useEffect, useState } from "react";
import "./BurgerMenu.scss"
import Button from "./Button";
import CaseMenu from "./CaseMenu";
import BurgerIcon from './../../assets/icons/ico_burger.svg'
import CloseIcon from './../../assets/icons/ico_close_white.svg'

const BurgerMenu = (props) => {
    const [burgerState, setBurgerState] = useState(props.burgerState)

    useEffect(() => {
        setBurgerState(props.burgerState)
    }, [props.burgerState])
    
    return(
        <div className={"BurgerMenu" + (burgerState?' visible':'')}>
            <div className="BurgerMenuIcon">
                <Button size={"large"} icon={burgerState?CloseIcon:BurgerIcon} onClick={() => {props.onClick(!burgerState)}}/>
            </div>
            <div className="BurgerContent">
                <div className="BurgerContentTitle"></div>
                {props.data && props.data.map((element, id) => (
                    <div key={id} className="BurgerCase">
                        <CaseMenu label={element.label} onClick={(e) => {props.handleCaseClick(e, id)}}/>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default BurgerMenu