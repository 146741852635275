import React, { PureComponent, useEffect, useRef, useState } from "react";
import "./App.scss"
import Home from "./Home";
import Menu from "./Menu";
import { injectIntl } from 'react-intl';
import About from "./About";
import Skills from "./Skills"
import ButtonToTop from "./components/ButtonToTop";
import Projects from "./Projects";
import Contact from "./Contact";
import Footer from "./Footer";
import Popup from "./components/Popup";
import Transition from "./components/Transition";
import Experience from "./Experience";

const App = (props) => {

    const [visiblePopup, setVisiblePopup] = useState(false)
    const [popupContent, setPopupContent] = useState(undefined)
    const [refs, setRefs] = useState([])

    const aboutRef = useRef();
    const experienceRef = useRef();
    const skillsRef = useRef();
    const projectsRef = useRef();
    const contactRef = useRef();

    useEffect(() => {
        setRefs([aboutRef.current, experienceRef.current, skillsRef.current, projectsRef.current, contactRef.current])
    }, [aboutRef.current, experienceRef.current, skillsRef.current, projectsRef.current, contactRef.current])

    useEffect(()=>{
        if(visiblePopup) document.body.style.overflow = 'hidden';
        else document.body.style.overflow = 'auto';
    }, [visiblePopup])

    const setPopupState = (visible, content) =>{
        setPopupContent(content)
        setVisiblePopup(visible)
    }

    const leavePopup = () =>{
        setVisiblePopup(false)
        setPopupContent(undefined)
    }

    return (
        <div className={"App" + (visiblePopup?' overflowHidden':'')}>
            <Home/>
            <Menu refs={refs} title={'name'}/>
            <About ref={aboutRef}/>
            <Experience ref={experienceRef}/>
            <Skills ref={skillsRef}/>
            <Projects ref={projectsRef} setVisiblePopup={setPopupState}/>
            {/* <Transition/> */}
            <Contact ref={contactRef}/>
            <Footer/>
            <Popup visible={visiblePopup} content={popupContent} leavePopup={leavePopup}/> 
            <ButtonToTop/>
        </div>
    )
}

export default App