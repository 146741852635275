import React, { PureComponent, useEffect, useRef, useState } from "react";
import "./Carousel.scss"
import ProgressBar from "./ProgressBar";
import { useIntl } from "react-intl";
import Heading from "./Heading";
import Button from './Button'
import Separator from "./Separator";
// import { useTransition } from 'react';
import { useTransition } from 'react-transition-state';
import LeftArrowIcon from './../../assets/icons/ico_left_arrow.svg'
import RightArrowIcon from './../../assets/icons/ico_right_arrow.svg'


const Carousel = (props) =>{
    const intl = useIntl(null)

    const [currentIndex, setCurrentIndex] = useState(0)
    const [initialTranslate, setInitialTranslate] = useState(0)
    const [clientWidth, setClientWidth] = useState(0)
    const [carouselWidth, setCarouselWidth] = useState(window.innerWidth)
    const [noTransition, setNoTransition] = useState(true)
    const [margin, setMargin] = useState(0)
    const [slidesVisible, setSlidesVisible] = useState(props.slidesVisible)

    useEffect(() => {
        setSlidesVisible(props.slidesVisible)
    }, [props.slidesVisible])

    const carouselElementRef = useRef()

    //Size of carousel

    useEffect(() => {
        if(window.innerWidth < 1300){
            setSlidesVisible(1)
        }else{
            setSlidesVisible(props.slidesVisible)
        }

        window.addEventListener('resize', updateCarouselSize);
    
        return () => {
          window.removeEventListener('resize', updateCarouselSize);
        };
    }, []);

    const updateCarouselSize = () => {
        setCarouselWidth(window.innerWidth)

        if(window.innerWidth < 1300){
            setSlidesVisible(1)
        }else{
            setSlidesVisible(props.slidesVisible)
        }
    }
    
    useEffect(() => {
        if(carouselElementRef.current?.clientWidth) setClientWidth(carouselElementRef.current.clientWidth)
    }, [carouselElementRef.current?.clientWidth, carouselWidth])

    useEffect(() => {
        setMargin((carouselWidth-(clientWidth*slidesVisible))/(slidesVisible+1))
    }, [carouselWidth])

    useEffect(() => {
        setMargin((carouselWidth-(clientWidth*slidesVisible))/(slidesVisible+1))
    }, [clientWidth])

    
    const prevIndex = (id) => {
        return id>=0?id:props.datas.length-1
    }

    const nextIndex = (id) => {
        return id<props.datas.length?id:0
    }

    const handleLeftButtonClick = (e) => {
        if(noTransition){
            clearTimeout()
            setNoTransition(false)
            setInitialTranslate(clientWidth+margin)
            setTimeout(()=>{
                setNoTransition(true)
                setCurrentIndex(prevIndex(currentIndex-1))
                setInitialTranslate(0)
            }, 500)
            setNoTransition(false)
        }
    }

    const handleRightButtonClick = (e) => {
        if(noTransition){
            clearTimeout()
            setNoTransition(false)
            setInitialTranslate(0-clientWidth-margin)
            setTimeout(()=>{
                setNoTransition(true)
                setCurrentIndex(nextIndex(currentIndex+1))
                setInitialTranslate(0)
            }, 500)
            setNoTransition(false)
        }
    }

    return (
        <div className="Carousel">
            <div className={"CarouselItems" + (noTransition?' noTransition':'')} style={{translate:initialTranslate-clientWidth-margin}}>
                <div className={"CarouselItem"} ref={carouselElementRef} style={{marginLeft:margin}}>{props.datas[prevIndex(currentIndex-1)]}</div>
                {Array.from({length:slidesVisible}).map((element, id) => (
                    <div key={id} className={"CarouselItem"} style={{marginLeft:margin}}>{props.datas[(currentIndex+id)%props.datas.length]}</div>
                ))}
                <div className={"CarouselItem"} style={{marginLeft:margin}}>{props.datas[(currentIndex+slidesVisible)%props.datas.length]}</div>
            </div>
            <div className="CarouselButton leftButton"><Button size={"small"} type={"primary"} icon={LeftArrowIcon} onClick={(e) => {handleLeftButtonClick(e)}}/></div>
            <div className="CarouselButton rightButton"><Button size={"small"} type={"primary"} icon={RightArrowIcon} onClick={(e) => {handleRightButtonClick(e)}}/></div>
        </div>
    )
}

export default Carousel