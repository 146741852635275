import React from "react";
import './Pages.scss'
import Heading from "./Heading";
import { useIntl } from "react-intl";
import Separator from "./Separator";

const Pages = (props) => {
    const intl = useIntl(null)
    return(
        <div className={"Page" + (props.small?" small":"") + (props.type===2?" type2":props.type===3?" type3":"")}>
            {props.title && <div className="PageTitle"><Heading size={'h2'} color={props.type===2?2:1} uppercase>{intl.formatMessage({id:props.title})}</Heading></div>}
            {props.subTitle && <div className="PageSubTitle"><Heading center size={'h3'} color={3}>{intl.formatMessage({id:props.subTitle})}</Heading></div>}
            {props.title && <Separator/>} 
            <div className="PageContent">
                {props.children}
            </div>
        </div>
    )
}

export default Pages