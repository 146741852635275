import React, { forwardRef } from 'react';
import "./About.scss"
import Heading from './components/Heading'
import Pages from "./components/Pages";
import { injectIntl } from "react-intl";
import Body from "./components/Body";
import Button from "./components/Button";
import Photo from './../assets/photo.jpg'
import Section from "./components/Section";
import DescriptionIcon from "./../assets/icons/ico_description.png"
import DiplomaIcon from "./../assets/icons/ico_diploma.png"
import SchoolIcon from "./../assets/icons/ico_school.png"
import DownloadIcon from "./../assets/icons/ico_download.svg"
import { redirect } from "react-router-dom";

const About = forwardRef((props, ref) => {

    const handleBtnClick = (event) => {
        window.open("./CV.pdf", "_blank");
    }

    return (
            <div ref={ref} className="About">
                <Pages small title={'aboutPage-title'} subTitle={'aboutPage-subtitle'}>
                    <div className="AboutContainer">
                        <div className="AboutLeftPart">
                            <img src={Photo}/>
                        </div>
                        <div className="AboutRightPart">
                            <Section icon={DescriptionIcon} title={"aboutPage-about-title"} description={"aboutPage-about-description"}/>
                            <Section icon={SchoolIcon} title={"aboutPage-education-title"} description={"aboutPage-education-description"}/>
                            {/* <Section icon={DiplomaIcon} title={"aboutPage-certification-title"} description={"aboutPage-certification-description"}/> */}
                            <div className="cvButton"><Button onClick={(e) => {handleBtnClick(e)}} size={'large'} type={"primary"} icon={DownloadIcon} label={'aboutPage-button'}/></div>
                        </div>
                    </div>
                </Pages>
            </div>
        )
})

export default About