import React, { PureComponent } from "react";
import "./Body.scss"

const Body = (props) => {
    let className = 'Body'
    if(props.color === 2) className += ' color2'
    else if(props.color === 3) className += ' color3'
    else if(props.color === 4) className += ' color4'
    else className += ' color1'

    return(
        <div className={className + (props.center?' center':'') + (props.small?' small':'')}>
            {props.children}
        </div>
    )
}

export default Body