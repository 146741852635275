import React, { PureComponent } from "react";
import "./Footer.scss"
import Heading from "./components/Heading";
import { useIntl } from "react-intl";
import Body from "./components/Body";
import Linkedin from './../assets/icons/linkedin.svg'
import LinkedinWhite from './../assets/icons/linkedin-white.svg'

//Linkedin - https://www.linkedin.com/in/ian-bertin-1b888a205/

const Footer = (props) =>{
    const intl = useIntl(null)

    return (
        <div className="Footer">
            <div className="FooterCopyright"><Body small color={3}>{intl.formatMessage({id : 'footer-copyright'})}</Body></div>
        </div>
    )
}

export default Footer