import React, { PureComponent } from "react";
import "./Heading.scss"

const Heading = (props) => {
    let color = ''
    if(props.color === 2) color += ' color2'
    else if(props.color === 3) color += ' color3'
    else if(props.color === 4) color += ' color4'
    else color += ' color1'

    return(
        <div className={'Heading ' + (props.size?props.size:'h1') + (props.uppercase?" uppercase":"") + (props.center?" center":"") + (color)}>
            {props.children}
        </div>
    )
}

export default Heading