import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import './Timeline.scss'
import TimelineCase from "./TimelineCase";

const Timeline = (props) => {
    const intl = useIntl()

    const [left, setLeft] = useState(0)

    useEffect(() => {
        setLeft()
    }, [props.datas])

    return(
        <div className="Timeline">
            <div className="TimelineContent">
                <div className="TimelineBar"/>
                <div className="TimelineDots">
                    {props.datas && props.datas.map((data, id) => (
                        <div className="TimelineDot" key={id}>
                            <div className="Dot"/>
                            <div className="TimelineDotDescription" style={(id+1)%2!==0?{right:'calc(50% + 50px)'}:{left:'calc(50% + 50px)'}}><TimelineCase left={(id+1)%2===0} data={data}/></div>
                        </div>
                    ))}
                </div>
            </div>
            
        </div>
    )
}

export default Timeline