import React, { useEffect, useState } from "react";
import "./Menu.scss"
import CaseMenu from "./components/CaseMenu";
import { useIntl } from "react-intl";
import Heading from "./components/Heading";
import BurgerMenu from "./components/BurgerMenu";

const Menu = (props) => {
    const intl = useIntl(null)

    const [isFixed, setIsFixed] = useState(false);
    const [isBurger, setIsBurger] = useState(window.innerWidth <= 900)
    const [burgerState, setBurgerState] = useState(false)
    const [refs, setRefs] =  useState(props.refs)

    const menu = [
        {label:'CaseMenu1', link:''},
        {label:'CaseMenu2', link:''},
        {label:'CaseMenu3', link:''},
        {label:'CaseMenu4', link:''},
        {label:'CaseMenu5', link:''},
    ]

    useEffect(() => {
        const handleScroll = () => {
        if (window.scrollY >= (1)) {
            setIsFixed(true);
        } else {
            setIsFixed(false);
        }
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResizePage);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResizePage);
        };
    }, []);

    useEffect(() => {
        setRefs(props.refs)
    }, [props.refs])

    const handleResizePage = () => {
        setIsBurger(window.innerWidth <= 900)
    }

    const handleCaseClick = (event, id) => {
        if(refs[id] || id === -1){
            window.scrollTo({
                top: id === -1?0:refs[id].offsetTop-60,
                behavior: 'smooth'
            })
            setBurgerState(false)
        }
    }

    return (
        <div className={"Menu" + (isFixed||burgerState?" FixedMenu":"")}>
            <div className="MenuTitle" onClick={(e) => {handleCaseClick(e, -1)}}><Heading size="h3" color={2}>{intl.formatMessage({id:props.title})}</Heading></div>
            {isBurger?
                <div className="MenuBurger">
                    <BurgerMenu data={menu} burgerState={burgerState} handleCaseClick={(e, id) => {handleCaseClick(e, id)}} onClick={(state) => {setBurgerState(state)}}/>
                </div>
            :
                <div className="MenuCases">
                    {menu.map((element, id) => (
                        <CaseMenu key={id} label={element.label} onClick={(e) => {handleCaseClick(e, id)}}/>
                    ))}
                </div>
            }
            
            
        </div>
    )
}

export default Menu