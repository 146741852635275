import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import {IntlProvider} from "react-intl";

import {en} from "./lang/en";
import {fr} from "./lang/fr";

const messages = {
    'en': en,
    'fr': fr,
};

const language = navigator.language.split(/[-_]/)[0];

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <IntlProvider onError={()=>{}} locale={navigator.language} messages={messages[language]}>
            <App messages={messages[language]}/>
        </IntlProvider>
    </React.StrictMode>
);

reportWebVitals(console.log);


//                 _
//             ,.-" "-.,
//            /   ===   \
//           /  =======  \
//        __|  (o)   (0)  |__      
//       / _|    .---.    |_ \         
//      | /.----/ O O \----.\ |       
//       \/     |     |     \/        
//       |                   |            
//       |                   |           
//       |                   |          
//       _\   -.,_____,.-   /_         
//   ,.-"  "-.,_________,.-"  "-.,
//  /          |       |          \  
// |           l.     .l           | 
// |            |     |            |
// l.           |     |           .l             
//  |           l.   .l           | \,     
//  l.           |   |           .l   \,    
//   |           |   |           |      \,  
//   l.          |   |          .l        |
//    |          |   |          |         |
//    |          |---|          |         |
//    |          |   |          |         |
//    /"-.,__,.-"\   /"-.,__,.-"\"-.,_,.-"\
//   |            \ /            |         |
//   |             |             |         |
//    \__|__|__|__/ \__|__|__|__/ \_|__|__/
//