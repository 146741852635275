import React, { PureComponent } from "react";
import "./ProgressBar.scss"
import { useIntl } from "react-intl";
import Body from "./Body";

const ProgressBar = (props) =>{
    const intl = useIntl(null)

    return (
        <div className="ProgressBar">
            {props.label &&<div className="ProgressBarLabel"><Body color={2}>{intl.formatMessage({id : props.label})}</Body></div>}
            <div className="Bar">
                <div className="BarFill" style={{width:props.progress+"%"}}/>
            </div>
        </div>
    )
}

export default ProgressBar