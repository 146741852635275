import React, { useEffect, useRef, useState } from "react";
import './Popup.scss'
import { useIntl } from "react-intl";
import Heading from "./Heading";
import Pages from "./Pages";
import Body from "./Body";
import Separator from "./Separator";
import CloseIcon from './../../assets/icons/ico_close.svg'
import Button from "./Button";

const Popup = (props) => {
    const intl = useIntl()
    const [visible, setVisible] = useState(props.visible)
    const [content, setContent] = useState(props.content)

    const popupRef = useRef()
    const popupBackgroundRef = useRef()

    useEffect(() => {
        setVisible(props.visible)
    }, [props.visible])

    useEffect(() => {
        setContent(props.content)
    }, [props.content])

    useEffect(()=>{
        window.addEventListener('keydown', (event) => {leavePopup(event)})
        window.addEventListener('click', (event) => {handleClick(event)})

        return () => {
            window.removeEventListener('keydown', (event) => {leavePopup(event)})
            window.removeEventListener('click', (event) => {handleClick(event)})
        }
    }, [])

    const handleClick = (event) => {
        if(popupBackgroundRef && popupRef && event.target !== popupRef.current && event.target === popupBackgroundRef.current){
            props.leavePopup()
        }
    }

    const leavePopup = (event) => {
        if (event.key === 'Escape'){
            props.leavePopup()
        }
    }

    return(
        <div className={"PopupBackground" + (visible?' visible':'')} ref={popupBackgroundRef}>
            <div className="Popup" ref={popupRef}>
                <div className="PopupCloseButton"><Button icon={CloseIcon} size={'small'} onClick={(e) => {props.leavePopup()}}/></div>
                {content}
            </div>
        </div>
    )
}

export default Popup