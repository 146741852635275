import React from "react";
import './Separator.scss'
import Heading from "./Heading";
import { useIntl } from "react-intl";

const Separator = (props) => {
    return(
        <div className={"Separator"} style={{width:(props.width?(props.width+(props.fix?'px':'%')):'80px')}}/>
    )
}

export default Separator