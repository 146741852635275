import React, { useEffect, useRef, useState } from "react";
import './PopupContent.scss'
import { useIntl } from "react-intl";
import Heading from "./Heading";
import Pages from "./Pages";
import Body from "./Body";
import Separator from "./Separator";
import CloseIcon from './../../assets/icons/ico_close.svg'
import Button from "./Button";
import DescriptionIcon from './../../assets/icons/ico_description.png'
import PicturesIcon from './../../assets/icons/ico_pictures.svg'
import SkillsUsedIcon from './../../assets/icons/ico_check.svg'

const PopupContent = (props) => {
    const intl = useIntl()

    const handleBtnLinkClick = (e, link, file=false) => {
        if(file){
            window.open(link, "_blank");
        }else{
            window.open(link, "_blank");
        }
    }

    useEffect(()=>{
        console.log("oui")
        return ()=>{
            console.log('destruction')
        }
    }, [])
 
    return(
        <div className="PopupContent">
            <div className="PopupTitleContent">
                <div className="PopupTitle"><Heading center size={'h3'}>{intl.formatMessage({id:props.datas.label})}</Heading></div>
                <div className="PopupDate"><Body center small color={3}>{intl.formatMessage({id:props.datas.date})}</Body></div>
                <div className="PopupSubTitle"><Body center color={3}>{intl.formatMessage({id:props.datas.description})}</Body></div>
                <Separator/>
            </div>
            
            <div className="PopupImage"><img src={props.datas.image169}/></div>

            <div className="PopupContainer">
                <div className="PopupContentLabel">
                    <img src={DescriptionIcon}/>
                    <div className="PopupDescriptionLabel"><Heading size={'h4'}>{intl.formatMessage({id:'description'})}</Heading></div>
                </div>
                <div className="PopupDescription"><Body>{intl.formatMessage({id:props.datas.longDescription})}</Body></div>
            </div>

            <div className="PopupContainer">
                <div className="PopupContentLabel">
                    <img src={PicturesIcon}/>
                    <div className="PopupDescriptionLabel"><Heading size={'h4'}>{intl.formatMessage({id:'visualizations'})}</Heading></div>
                </div>
                {props.datas.visualizations?
                    <div className="PopupVisu">
                        {props.datas.visualizations.map((visualization, id) => (
                            <img key={(id)} src={visualization}/>
                        ))}
                        {props.datas.file && <div className={'fileButton'}><Button type={'primary'} size={'small'} label={'projectsPage-dl-project-button'} onClick={(e) => {handleBtnLinkClick(e, intl.formatMessage({id:props.datas.file}), true)}}/></div>}
                    </div>
                :
                    <>
                        {props.datas.link && <div className="PopupVisu"><Button type={'primary'} size={'small'} label={'projectsPage-view-project-button'} onClick={(e) => {handleBtnLinkClick(e, intl.formatMessage({id:props.datas.link}))}}/></div>}
                        {props.datas.videoLink && <div className="PopupVisu"><video width={'100%'} controls>
                            <source src={props.datas.videoLink} type="video/mp4" />
                        </video></div>}
                    </>
                }
            </div>

            <div className="PopupContainer">
                <div className="PopupContentLabel">
                    <img src={SkillsUsedIcon}/>
                    <div className="PopupDescriptionLabel"><Heading size={'h4'}>{intl.formatMessage({id:'skills-used'})}</Heading></div>
                </div>
                {props.datas.skills &&<div className="PopupSkills">
                    {props.datas.skills.map((skill, id) => (
                        <div className="PopupSkill" key={id}><Body color={2}>{intl.formatMessage({id:skill})}</Body></div>
                    ))}
                </div>}
                {props.datas.techSkills && <div className="PopupTechnicalSkills">
                    {props.datas.techSkills.map((skill, id) => (
                        <div className="PopupTechnicalSkill" key={id}><Body>{intl.formatMessage({id:skill})}</Body></div>
                    ))}
                </div>}
                
            </div>

        </div>
    )
}

export default PopupContent