import React, { PureComponent } from "react";
import "./Section.scss"
import { useIntl } from "react-intl";
import Heading from "./Heading";
import Body from "./Body";

const Section = (props) => {
    const intl = useIntl(null)

    return(
        <div className={'Section'}>
            {props.title && <div className="SectionTitle">
                {props.icon && <div className="SectionIcon"><img src={props.icon}/></div>}
                <Heading size={'h4'}>{intl.formatMessage({id : props.title})}</Heading>
            </div>}
            {props.description && <div className="SectionDescription"><Body>{intl.formatMessage({id : props.description})}</Body></div>}
        </div>
    )
}

export default Section