import React, { PureComponent } from "react";
import "./SkillContent.scss"
import ProgressBar from "./ProgressBar";
import { useIntl } from "react-intl";
import Heading from "./Heading";
import Separator from "./Separator";


const SkillContent = (props) =>{
    const intl = useIntl(null)

    return (
        <div className="SkillContent">
            {props.title && 
            <div className="SkillContentTitle">
                {props.icon && <div className="SkillIcon"><img src={props.icon}/></div>}
                <div className="SkillTitle"><Heading color={2} size={'h3'}>{intl.formatMessage({id : props.title})}</Heading></div>
            </div>}
            {/* <Separator width={30}/> */}
            <div className="SkillsDetails">
                {props.skills && 
                    props.skills.map((skill, id) => (
                        <div className="SkillProgressBar" key={id}><ProgressBar label={skill.label} progress={skill.progress}/></div>
                    ))
                }
            </div>
        </div>
    )
}

export default SkillContent