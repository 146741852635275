import React, { useState, useEffect } from "react";
import './ButtonToTop.scss'
import { useIntl } from "react-intl";
import ArrowUp from '../../assets/icons/ico_arrow_up.svg'
import Button from "./Button";

const ButtonToTop = (props) => {
    const [activate, setActivate] = useState(false)

    useEffect(() => {
        const handleScroll = () => {
        if (window.scrollY > 0) {
            setActivate(true);
        } else {
            setActivate(false);
        }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleButtonClick = (e) =>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    
    return(
        <div className={"ButtonToTop" + (activate?" activate":" desactivate")}>
            <Button circle type={'primary'} size={"small"} icon={ArrowUp} onClick={(e) => {handleButtonClick(e)}}/>
        </div>
    )
}

export default ButtonToTop