import React, { forwardRef, useRef, useState } from 'react';
import "./Contact.scss"
import Pages from "./components/Pages";
import Heading from "./components/Heading";
import Body from "./components/Body";
import Button from "./components/Button";
import { useIntl } from "react-intl";
import PhoneIcon from './../assets/icons/ico_call.svg'
import MailIcon from './../assets/icons/ico_mail.svg'
import LocationIcon from './../assets/icons/ico_location.svg'
import SocialNetworkIcon from './../assets/icons/ico_social_network.svg'
import Linkedin from './../assets/icons/linkedin.svg'
import LinkedinHover from './../assets/icons/linkedin-white.svg'
import Input from "./components/Input";
import emailjs from '@emailjs/browser';

const CoordonneeContainer = (props) => {
    const intl = useIntl()

    const containerRef = useRef()

    const [isHover, setIsHover] = useState(false)

    const handleMouseEnter = (e) => {
        setIsHover(true)
    }

    const handleMouseLeave = (e) => {
        setIsHover(false)
    }

    const handleCoordonneeClick = (e, link) => {
        window.open(link, "_blank");
    }

    return (
        <div ref={containerRef} className={"CoordonneeContainer" + (props.first?' first':'')}>
            <div className="CoordonneeIcon"><img src={props.icon}/></div>
            <div className="CoordonneeContent">
                <div className="CoordonneeLabel"><Heading size={'h4'}>{intl.formatMessage({id : props.label})}</Heading></div>
                {props.coordonnee && <div className="Coordonnee"><Body>{intl.formatMessage({id : props.coordonnee})}</Body></div>}
                {props.coordonneeIcon && <div onMouseEnter={(e) => {handleMouseEnter(e)}} onMouseLeave={(e) => {handleMouseLeave(e)}} className="CoordonneeButton"><Button icon={isHover?props.coordonneeIconHover:props.coordonneeIcon} onClick={(e) => {handleCoordonneeClick(e, intl.formatMessage({id:props.coordonneeLink}))}}/></div>}
            </div>
        </div>
    )
}

const Contact = forwardRef((props, ref) =>{
    const intl = useIntl()

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [object, setObject] = useState('')
    const [message, setMessage] = useState('')

    const formRef = useRef()

    const handleNameChange = (event) => {
        if(event.target.value !== name) setName(event.target.value)
    }

    const handleEmailChange = (event) => {
        if(event.target.value !== email) setEmail(event.target.value)
    }

    const handleObjectChange = (event) => {
        if(event.target.value !== object) setObject(event.target.value)
    }

    const handleMessageChange = (event) => {
        if(event.target.value !== message) setMessage(event.target.value)
    }

    const sendEmail = (e) => { 
        e.preventDefault(); 
        emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, formRef.current, process.env.REACT_APP_PUBLIC_KEY).then((result) => { 
            alert('message sent successfully...')
            setName('')
            setEmail('')
            setObject('')
            setMessage('')
        }, (error) => {console.log(error.text)})}


    const handleSubmitForm = (event) => {
        if(name !== '' && email!=='' && object!='' && message!=''){
            sendEmail(event)
        }else{
            console.log("Error ! empty fields")
        }
    }

    return (
        <div className="Contact" ref={ref}>
            <Pages title={'contactPage-title'} subTitle={'contactPage-subtitle'}>
                <div className="ContactContent">
                    <div className="ContactCoordonnees">
                        <CoordonneeContainer first icon={PhoneIcon} label={'contactPage-contact-call-me'} coordonnee={'contactPage-contact-phone-number'}/>
                        <CoordonneeContainer icon={MailIcon} label={'contactPage-contact-email'} coordonnee={'contactPage-contact-email-address'}/>
                        <CoordonneeContainer icon={LocationIcon} label={'contactPage-contact-address'} coordonnee={'contactPage-contact-location'}/>
                        <CoordonneeContainer icon={SocialNetworkIcon} label={'contactPage-contact-sn'} coordonneeIcon={Linkedin} coordonneeIconHover={LinkedinHover} coordonneeLink={'linkedin-link'}/>
                    </div>
                    <div className="ContactEmailForm">
                        <form className="ContactForm" ref={formRef} onSubmit={(e) => {handleSubmitForm(e)}}>
                            <div className="ContactFormTitle"><Heading size={'h5'}>{intl.formatMessage({id:'contactPage-form-title'})}</Heading></div>
                            <div className="ContactFormInput"><Input type={'name'} name={'form_name'} value={name} onChange={(event) => {handleNameChange(event)}} required placeholder={'contactPage-input-name'}/></div>
                            <div className="ContactFormInput"><Input type={'email'} name={'form_email'} value={email} onChange={(event) => {handleEmailChange(event)}} required placeholder={'contactPage-input-email'}/></div>
                            <div className="ContactFormInput"><Input type={'text'} name={'form_object'} value={object} onChange={(event) => {handleObjectChange(event)}} required placeholder={'contactPage-input-object'}/></div>
                            <div className="ContactFormInput"><Input type={'textarea'} name={'form_message'} value={message} onChange={(event) => {handleMessageChange(event)}} required placeholder={'contactPage-input-message'}/></div>
                            <div className="ContactFormButton"><Button type={'primary'} submit label={'submit'} onClick={(event) => {handleSubmitForm(event)}}/></div>
                        </form>
                    </div>
                </div>
            </Pages>
        </div>
    )
})

export default Contact