import React, { forwardRef } from 'react';
import "./Skills.scss"
import Heading from './components/Heading'
import Pages from "./components/Pages";
import Photo from '../assets/photo.jpg'
import { injectIntl } from "react-intl";
import Body from "./components/Body";
import Button from "./components/Button";
import SkillContent from "./components/SkillContent";
import ComputerIcon from './../assets/icons/ico_computer.svg'
import DevOoIcon from './../assets/icons/ico_devOO.svg'
import MobileIcon from './../assets/icons/ico_phone.svg'
import BddIcon from './../assets/icons/ico_database.svg'


const Skills = forwardRef((props, ref) =>{
    const webSkills = [
        {label:'html', progress:100},
        {label:'css', progress:100},
        {label:'js', progress:80},
        {label:'react', progress:90},
        {label:'npm', progress:85},
        {label:'php', progress:70},
    ]

    const ooSkills = [
        {label:'cpp', progress:90},
        {label:'java', progress:65},
        {label:'csharp', progress:60},
        {label:'py', progress:80},
    ]

    const mobileSkills = [
        {label:'angular', progress:70},
        {label:'ionic', progress:65},
    ]

    const bddSkills = [
        {label:'mysql', progress:90},
        {label:'sqlite', progress:75},
        {label:'sql', progress:85},
        {label:'mongo', progress:65},
    ]

    return (
        <div className="Skills" ref={ref}>
            <Pages type={2} title={'skillsPage-title'} subTitle={'skillsPage-subtitle'}>
                <div className="SkillsContainer">
                    <div className="SkillsContents"> 
                        <SkillContent title={'skillsPage-devweb'} icon={ComputerIcon} skills={webSkills}/>
                        <SkillContent title={'skillsPage-devmobile'} icon={MobileIcon} skills={mobileSkills}/>
                    </div>
                    <div className="SkillsContents">
                        <SkillContent title={'skillsPage-devoo'} icon={DevOoIcon} skills={ooSkills}/>
                        <SkillContent title={'skillsPage-bdd'} icon={BddIcon} skills={bddSkills}/>
                    </div>
                </div>
            </Pages>
        </div>
    )
})

export default Skills