import React, { forwardRef } from 'react';
import "./Projects.scss"
import Pages from "./components/Pages";
import CarouselContent from "./components/CarouselContent";
import Carousel from "./components/Carousel";
//Projet 1
import Project1 from "./../assets/projects/project1/accueil.png"
import Project1_16_9 from "./../assets/projects/project1/accueil-16-9.png"
import Project1_1 from "./../assets/projects/project1/analyseTeam.png"
import Project1_2 from "./../assets/projects/project1/descPokemon.png"
//Projet 2
import Project2 from "./../assets/projects/project2/accueil.png"
import Project2_16_9 from "./../assets/projects/project2/accueil-16-9.png"
import Project2_1 from "./../assets/projects/project2/detail.png"
import Project2_2 from "./../assets/projects/project2/liste.png"
import Project2_3 from "./../assets/projects/project2/planning.png"
//Projet 3
import Project3 from "./../assets/projects/project3/accueil.png"
import Project3_16_9 from "./../assets/projects/project3/accueil-16-9.png"
//Projet 4
import Project4 from "./../assets/projects/project4/accueil.png"
import Project4_16_9 from "./../assets/projects/project4/accueil-16-9.png"
import Project4_video from "./../assets/projects/project4/video-presentation.mp4"
//Projet 5
import Project5 from "./../assets/projects/project5/accueil.png"
import Project5_16_9 from "./../assets/projects/project5/accueil.png"
//Projet 6
import Project6 from "./../assets/projects/project6/accueil.png"
import Project6_16_9 from "./../assets/projects/project6/accueil-16-9.png"
import Project6_1 from "./../assets/projects/project6/niveau1.png"
import Project6_2 from "./../assets/projects/project6/niveau2.png"
import Project6_3 from "./../assets/projects/project6/niveau3.png"

const Projects = forwardRef((props, ref) =>{
    const projects = [
        {label:"project1-title", description:'project1-description', longDescription:'project1-longDescription', date:'project1-date', visualizations:[Project1, Project1_1, Project1_2], skills:['js', 'css', 'npm', 'webpack'], image:Project1, image169:Project1_16_9},
        {label:"project2-title", description:'project2-description', longDescription:'project2-longDescription', date:'project2-date', visualizations:[Project2, Project2_1, Project2_2, Project2_3], skills:['react', 'css', 'npm', 'webpack'], image:Project2, image169:Project2_16_9},
        {label:"project3-title", description:'project3-description', longDescription:'project3-longDescription', date:'project3-date', link:'project3-link', skills:['agile', 'owner', 'teamwork', 'cpp', 'html', 'css', 'js'], image:Project3, image169:Project3_16_9},
        {label:"project4-title", description:'project4-description', longDescription:'project4-longDescription', date:'project4-date', videoLink:Project4_video, skills:['html', 'css', 'js', 'teamwork'], image:Project4, image169:Project4_16_9},
        {label:"project5-title", description:'project5-description', longDescription:'project5-longDescription', date:'project5-date', image:Project5, image169:Project5_16_9, visualizations:[Project5], skills:['react', 'git', 'jest', 'css', 'npm'],},
        {label:"project6-title", description:'project6-description', longDescription:'project6-longDescription', date:'project6-date' ,file:'project6-file-name', image:Project6, image169:Project6_16_9, visualizations:[Project6_1, Project6_2, Project6_3], skills:['unity', 'teamwork', 'csharp', 'git']}
    ]

    const handleCarouselContentClick = (e, content) =>{
        props.setVisiblePopup(true, content)
    }

    const carouselContent = () => {
        return (
            Array.from({length : projects.length}).map((element, id) => (
                <CarouselContent onClick={(e, content) => {handleCarouselContentClick(e, content)}} data={projects[id]}/>
            ))
        )
    }

    return (
        <div className="Projects" ref={ref}>
            <Pages title={'projectsPage-title'} subTitle={'projectsPage-subtitle'}>
                <div className="ProjectsContent">
                    <Carousel 
                        infinite 
                        datas={carouselContent()}
                        slidesVisible = {3}
                        slidesToScroll = {1}    
                    />
                </div>
            </Pages>
        </div>
    )
})

export default Projects