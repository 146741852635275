import "./CaseMenu.scss"
import Body from "./Body"
import { useIntl } from "react-intl"

const CaseMenu = (props) => {
    const intl = useIntl(null)

    return(
        <div className="CaseMenu" onClick={props.onClick}>
            <div className="CaseMenuLabel"><Body color={2}>{intl.formatMessage({id:props.label})}</Body></div>
            {/* <div className="CaseMenuIcon"><img src={props.icon} className="MenuIcon"/></div> */}
        </div>
    )
}

export default CaseMenu